// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext,
	useEffect,
	useMemo,
	useReducer,
	FC,
	ReactNode,
	useCallback,
} from 'react';

import { FirebaseApp, initializeApp } from 'firebase/app';
import {
	getAuth,
	signInWithEmailAndPassword,
	signOut,
	setPersistence,
	indexedDBLocalPersistence,
	onAuthStateChanged,
	User,
	OAuthProvider,
	signInWithPopup,
} from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { firebaseConfig } from '../config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const INITIALIZE = 'INITIALIZE';
const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	_user: null,
	username: '',
	avatar: null,
};
type AuthState = {
	isAuthenticated?: boolean;
	isInitialized?: boolean;
	_user?: User | null;
	customer_id?: string;
	role?: string;
	username?: string;
	newAvatar?: any;
	profile?: any;
	avatar?: any;
};
const CHANGEAVATAR = 'changeAvatar';
const reducer: (state: AuthState, action: { type: string; payload: AuthState }) => AuthState = (
	state,
	action,
) => {
	switch (action.type) {
		case INITIALIZE:
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const { isAuthenticated, _user } = action.payload;

			return {
				...state,
				isAuthenticated,
				isInitialized: true,
				...action.payload,
				_user,
			};
		case CHANGEAVATAR:
			const { newAvatar } = action.payload;
			console.log('changing');
			return {
				...state,

				user: { ..._user, avatar: newAvatar },
			};
		default:
			break;
	}

	return state;
};
export interface AuthContextType extends AuthState {
	method: string;
	user: {
		id: string | null;
		email: string | null;
		displayName: string | null;
		avatar?: string | null;
		role: string | null;
		customer_id: string | null;
		username: string | null;
		newAvatar?: any | null;
		provider?: string | null;
	};
	signIn: (email: string, password: string, persistLogin: boolean) => Promise<void>;
	logOut: () => void;
	signinWithMicrosoft: () => {};
	changeAvatar: (newAvatar: any) => {};
	app: FirebaseApp;
	tenantDb: Firestore;
}

interface AuthProviderProps {
	children?: ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const app = useMemo(() => {
		return initializeApp(firebaseConfig);
	}, []);
	const tenantDb = useMemo(() => {
		return getFirestore(app);
	}, [app]);
	const auth = getAuth();

	const signIn: (
		email: string,
		password: string,
		persistLogin: boolean,
	) => Promise<void> = async (email, password, persistLogin) => {
		window.localStorage.setItem('lastLoginAt', JSON.stringify(Date.now()));
		if (!persistLogin) await signInWithEmailAndPassword(auth, email, password);
		else {
			await setPersistence(auth, indexedDBLocalPersistence);
			await signInWithEmailAndPassword(auth, email, password);
		}
	};
	const signinWithMicrosoft = () => {
		window.localStorage.setItem('lastLoginAt', JSON.stringify(Date.now()));

		const provider = new OAuthProvider('microsoft.com');
		provider.setCustomParameters({
			// Force re-consent.
			prompt: 'consent',
			tenant: 'marklite.com',
		});
		const authMicrosoft = getAuth();
		return signInWithPopup(authMicrosoft, provider);
	};

	const logOut = useCallback(async () => {
		console.log('signing out');
		await signOut(auth);
		dispatch({
			type: INITIALIZE,
			payload: { isAuthenticated: false, _user: null },
		});
	}, [auth]);
	useEffect(
		() =>
			onAuthStateChanged(auth, async (user) => {
				const lastLoginAt =
					JSON.parse(window.localStorage.getItem('lastLoginAt') as string) || Date.now();
				if (user) {
					const idTokenResult = await user.getIdTokenResult();
					const claims = idTokenResult.claims;

					if (lastLoginAt < Date.now() - 1800000) {
						console.log('lastlogin');
						logOut();
					}
					axios.defaults.headers.common.Authorization =
						'Bearer ' + (await user.getIdToken());

					dispatch({
						type: INITIALIZE,
						payload: {
							isAuthenticated: true,
							_user: user,
							role: claims.role,
							customer_id: claims.customer_id,
						},
					});
				} else {
					console.log('no user');
					dispatch({
						type: INITIALIZE,
						payload: { isAuthenticated: false, _user: null },
					});
				}
			}),
		[dispatch, logOut],
	);
	const changeAvatar = async (avatar: any) => {
		dispatch({
			type: CHANGEAVATAR,
			payload: { avatar },
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'firebase',
				user: {
					id: state._user?.uid.toUpperCase() || '',
					email: state._user?.email || '',
					avatar: state.avatar || '',
					displayName: state._user?.displayName || '',
					role: state.role || '',
					customer_id: state.customer_id || '',
					username: state.username || '',
					provider: state._user?.providerData[0].providerId || '',
				},
				signIn,
				logOut,
				signinWithMicrosoft,
				changeAvatar,
				app,
				tenantDb,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };

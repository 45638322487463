import React, { FC } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
interface IMarkLiteLogoProps {
	width?: number;
	height?: number;
}
const MarkLiteLogo: FC<IMarkLiteLogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			// viewBox='0 0 1231.512 114.636'
			fill='none'
			viewBox='0 0 454.59 110.12'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='Layer_6_copy_2' data-name='Layer 6 copy 2'>
				<rect className='cls-2' y='0' width='110.1' height='110.1' rx='35' ry='35' />
				<path
					className='cls-1'
					d='m106.78,41.44v-20.65c-.11-2.7-.7-8.83-4.66-14.21-3.45-4.69-7.47-6.12-8.15-6.25-.64-.12-1.33-.2-1.33-.2-.56-.07-1.03-.1-1.37-.11-.46-.02-.85-.02-1.13-.02,1.08,1.2,2.74,3.33,3.85,6.41,1.12,3.08,1.21,5.77,1.15,7.39v34.53c0,3.8,2.6,6.88,5.81,6.88-3.21,0-5.81,3.08-5.81,6.88v34.42c.05,1.65-.08,4.34-1.19,7.4-1.06,2.9-2.57,4.96-3.62,6.18.93.06,2.32.04,3.91-.39,3.93-1.08,6.17-3.93,7.87-6.09,2.68-3.41,3.66-6.7,3.98-7.92.63-2.45.72-4.58.68-6.06v-20.65c0-7.6,5.21-13.77,11.62-13.77-6.43,0-11.62-6.15-11.62-13.77Z'
				/>
				<path
					className='cls-3'
					d='m110.7,41.44v-20.65c.1-1.22.59-8.28-4.66-14.21-4.63-5.23-10.61-6.08-12.07-6.24,1.07,1.07,2.79,3.07,3.95,6.07,1.21,3.12,1.24,5.86,1.15,7.39v34.53c0,3.8,2.6,6.88,5.81,6.88-3.21,0-5.81,3.08-5.81,6.88v34.42c.08,1.57,0,4.3-1.19,7.4-1.07,2.77-2.6,4.69-3.63,5.79,1.8-.23,7.6-1.22,11.8-6.09,2.69-3.13,3.64-6.6,3.98-7.92.63-2.45.72-4.58.68-6.06v-20.65c0-7.6,5.21-13.77,11.62-13.77-6.43,0-11.62-6.15-11.62-13.77Z'
				/>
				<g>
					<path
						className='cls-1'
						d='m25.73,49.35c.84-.38,1.91-.7,3.2-.94,1.29-.25,2.36-.37,3.2-.37,1.56,0,3.02.25,4.37.74,1.35.49,2.72,1.04,4.1,1.64,1.44-.76,3.08-1.35,4.91-1.76,1.83-.41,3.68-.61,5.54-.61,1.68,0,3.21.36,4.59,1.06,1.38.71,2.55,1.62,3.51,2.74.96,1.12,1.71,2.39,2.25,3.81.54,1.42.81,2.84.81,4.26v18.5h-9.19v-11.54c0-3.98-.38-6.93-1.13-8.84-.75-1.91-2.36-2.87-4.82-2.87-.72,0-1.29.1-1.71.29-.42.19-.87.56-1.35,1.11v21.86h-9.19v-11.54c0-3.93-.36-6.86-1.08-8.8-.72-1.94-2.13-2.91-4.23-2.91-.6,0-1.28.15-2.03.45-.75.3-1.34.67-1.76,1.11v21.7h-9.19v-29.56h9.19v.49Z'
					/>
					<path className='cls-1' d='m74.11,24.13v54.29h-9.19V24.13h9.19Z' />
				</g>
				<circle className='cls-4' cx='80.26' cy='74.36' r='4.06' />
			</g>
			<g id='Layer_9' data-name='Layer 9'>
				<g>
					<path
						className='cls-2'
						d='m143.34,39.69c1.19-.59,2.7-1.08,4.53-1.47,1.83-.38,3.34-.57,4.53-.57,2.21,0,4.27.38,6.19,1.15,1.91.76,3.85,1.61,5.8,2.55,2.04-1.19,4.36-2.1,6.95-2.74,2.59-.64,5.21-.96,7.84-.96,2.38,0,4.55.55,6.5,1.66,1.95,1.11,3.61,2.53,4.97,4.27,1.36,1.74,2.42,3.72,3.19,5.93.77,2.21,1.15,4.42,1.15,6.63v28.8h-12.91v-17.92c0-6.18-.54-10.76-1.61-13.73-1.08-2.96-3.38-4.45-6.91-4.45-1.03,0-1.85.15-2.45.44-.6.3-1.25.87-1.94,1.72v33.93h-12.91v-17.92c0-6.1-.51-10.65-1.53-13.66-1.02-3.01-3.03-4.51-6.01-4.51-.85,0-1.81.23-2.88.7-1.07.47-1.9,1.04-2.49,1.72v33.68h-12.91v-46h12.91v.76Z'
					/>
					<path
						className='cls-2'
						d='m233.73,38.92h12.11v46h-12.11v-1.52c-1.6.76-3.4,1.31-5.42,1.65-2.02.34-3.74.51-5.17.51-3.45,0-6.62-.57-9.52-1.71-2.9-1.14-5.42-2.74-7.57-4.82-2.14-2.07-3.83-4.54-5.04-7.41-1.22-2.87-1.83-6-1.83-9.38s.71-6.29,2.14-9.25c1.43-2.96,3.3-5.58,5.61-7.86,2.31-2.28,4.96-4.1,7.94-5.45,2.98-1.35,6.07-2.03,9.27-2.03,2.35,0,4.24.25,5.67.76,1.43.51,2.73.89,3.91,1.14v-.63Zm-23.3,23.16c0,4.07,1.33,7.33,4,9.79,2.67,2.46,6.04,3.69,10.1,3.69,2.96,0,5.72-.64,8.26-1.91l.13-.13v-21.35c-1.19-1.1-2.69-1.93-4.51-2.48-1.82-.55-3.37-.83-4.64-.83-4.07,0-7.31,1.19-9.72,3.56-2.41,2.37-3.62,5.59-3.62,9.66Z'
					/>
					<path
						className='cls-2'
						d='m263.59,38.92v2.5c1.38-.97,2.98-1.76,4.78-2.38,1.81-.62,3.55-.93,5.25-.93h3.69v12.11h-5.53c-2.08,0-3.96.69-5.65,2.07-1.69,1.38-2.54,3.14-2.54,5.28v27.36h-12.91v-46h12.91Z'
					/>
					<path
						className='cls-2'
						d='m294.26,9.87v49.95l17.97-20.9,12.87-.13-18.6,22.94,21.02,23.19h-15.16l-18.1-20.12v20.12h-12.91V9.87h12.91Z'
					/>
					<path className='cls-2' d='m345.1,9.87v75.05h-12.91V9.87h12.91Z' />
					<path className='cls-2' d='m349.94,38.92h12.11v46h-12.11v-46Z' />
					<path
						className='cls-2'
						d='m383.03,19.37v19.55h8.88v9.68h-8.88v15.12c0,3.98.41,6.78,1.22,8.39.81,1.61,2.5,2.41,5.07,2.41.85,0,1.71-.15,2.57-.44.85-.3,1.67-.66,2.44-1.08v11.84c-.94.51-2.08.89-3.44,1.15-1.36.25-2.63.38-3.82.38-3.4,0-6.18-.47-8.35-1.4-2.17-.93-3.89-2.12-5.16-3.57-1.27-1.44-2.17-3.01-2.68-4.71-.51-1.7-.76-3.31-.76-4.84v-23.25h-4.81c.76-1.61,1.54-3.05,2.34-4.32.8-1.27,1.62-2.71,2.47-4.32v-15.12l12.91-5.47Z'
					/>
					<path
						className='cls-2'
						d='m419.15,37.76c2.88,0,5.69.44,8.42,1.33,2.73.89,5.14,2.25,7.25,4.07,2.1,1.82,3.8,4.13,5.09,6.93s1.93,6.06,1.93,9.79c0,1.02-.04,2.06-.12,3.11-.08,1.06-.23,2.18-.47,3.37h-32.51c.16,1.78.7,3.33,1.64,4.64.94,1.31,2.09,2.37,3.45,3.18,1.36.81,2.81,1.4,4.33,1.78,1.52.38,2.94.57,4.27.57,2.96,0,5.48-.51,7.54-1.52,2.06-1.02,4.11-2.16,6.14-3.43l4.09,8.77c-2.18,1.95-4.93,3.41-8.24,4.38-3.31.97-6.53,1.46-9.65,1.46-8.03,0-14.31-2.14-18.83-6.42-4.52-4.28-6.78-10.36-6.78-18.24,0-3.3.6-6.42,1.81-9.34,1.21-2.92,2.85-5.44,4.91-7.56,2.06-2.12,4.46-3.79,7.19-5.02,2.73-1.23,5.57-1.84,8.54-1.84Zm10.17,19.59c0-2.54-.88-4.62-2.63-6.23-1.75-1.61-3.76-2.41-6.02-2.41-2.96,0-5.36.59-7.19,1.78-1.83,1.19-3.41,3.52-4.74,6.99l20.58-.13Z'
					/>
				</g>
				<circle className='cls-3' cx='355.99' cy='25.61' r='6.05' />
				<path
					className='cls-2'
					d='m446.91,80.37v5.18h-1.23v-5.18h-1.54v-1.15h4.31v1.15h-1.54Z'
				/>
				<path
					className='cls-2'
					d='m448.57,85.55v-6.34h1.14l1.87,3.69c.3-.61.62-1.23.94-1.85s.63-1.24.94-1.84h1.13v6.34h-1.18s-.03-3.55-.03-3.55c-.21.41-.42.83-.63,1.23-.21.41-.42.81-.62,1.22-.03.07-.07.15-.12.25-.05.1-.1.2-.15.3s-.1.2-.15.3c-.05.1-.09.18-.12.25l-.54-1.1c-.12-.24-.23-.46-.33-.65-.1-.19-.2-.38-.29-.57-.09-.18-.19-.37-.29-.57-.1-.2-.21-.42-.33-.67v3.55s-1.22,0-1.22,0Z'
				/>
			</g>
		</svg>
	);
};
MarkLiteLogo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
MarkLiteLogo.defaultProps = {
	width: 2155,
	height: 854,
};

export default MarkLiteLogo;

import React, { FC, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Microsoft } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';

import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import LogoDark from '../../../components/LogoDark';
import useAuth from '../../../hooks/useAuth';
import { authPagesML } from '../../../menu';
// import { ChangeThemeMode } from './ChangeThemeMode';
import { useSetState } from 'react-use';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

interface ILoginProps {
	isSignUp?: boolean;
}
const AdminLogin: FC<ILoginProps> = ({}) => {
	const [darkModeStatus, setDarkModeStatus] = useState();

	const { signinWithMicrosoft } = useAuth();

	return (
		<PageWrapper title={authPagesML.adminlogin.text} isProtected={false}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								{darkModeStatus ? (
									<div className='text-center my-5'>
										<LogoDark width={200} />
										<hr></hr>
									</div>
								) : (
									<div className='text-center my-5'>
										<Logo width={200} />
									</div>
								)}

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<Button
												style={{
													backgroundColor: '#00A4EF',
													color: '#FFF',
												}}
												className='w-100 py-3 align-items-center'
												// isDisable={!formik.values.loginUsername}
												onClick={signinWithMicrosoft}>
												<Microsoft
													size={20}
													style={{ color: '#fff', marginRight: '2%' }}
												/>
												Sign In With Microsoft
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
AdminLogin.propTypes = {
	isSignUp: PropTypes.bool,
};
AdminLogin.defaultProps = {
	isSignUp: false,
};

export default AdminLogin;

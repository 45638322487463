import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import { dashboardPagesAdminMenuML, dashboardPagesCustMenuML } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import CustUser from '../../../layout/User/CustUser';
import useAuth from '../../../hooks/useAuth';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();
	const { user } = useAuth();

	return (
		<Aside>
			{darkModeStatus ? (
				<AsideHead
					className='row align-items-center  rounded-0 '
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						right: 0,
						backgroundColor: '#000329',
					}}>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</AsideHead>
			) : (
				<AsideHead
					className='row align-items-center bg-white rounded-0 '
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						right: 0,
						background: 'aqua',
					}}>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</AsideHead>
			)}
			<span className='mt-5'></span>
			<AsideBody className='mt-5'>
				{!doc && (
					<>
						{user?.provider === 'microsoft.com' ? (
							<Navigation menu={dashboardPagesAdminMenuML} id='aside-dashboard' />
						) : (
							<Navigation menu={dashboardPagesCustMenuML} id='aside-dashboard' />
						)}
						{/* <NavigationLine /> */}
						{/* <Navigation menu={demoPagesMenu} id='aside-demo-pages' /> */}
						{/* <NavigationLine /> */}
						{/* <Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' /> */}
					</>
				)}

				{asideStatus && doc && (
					<Card className='m-3 '>
						<CardBody className='pt-0'>
							<img srcSet={HandWebp} src={Hand} alt='Hand' width={130} height={130} />
							<p
								className={classNames('h4', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								{t('Everything is ready!') as ReactNode}
							</p>
							<Button
								color='info'
								isLight
								className='w-100'
								onClick={() => {
									localStorage.setItem('facit_asideDocStatus', 'false');
									setDoc(false);
								}}>
								{t('Demo Pages') as ReactNode}
							</Button>
						</CardBody>
					</Card>
				)}
			</AsideBody>
			<AsideFoot>
				<CustUser />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import { componentPagesMenu, dashboardPagesAdminMenuML } from '../menu';
import { DataContext } from '../contexts/UserDataContext';
import { Row } from 'react-bootstrap';
import Spinner from './bootstrap/Spinner';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

const licensesSearchParam = ['license_id', 'limit_type', 'limit_quantity', 'expiry_date', 'status'];
const customersSearchParam = ['customer_id', 'customer_name', 'customer_country', 'email'];
const usersSearchParam = ['username', 'email', 'role'];
const productsSearchParam = ['product_id', 'product_name', 'version_option', 'limit_type'];
const logsSearchParam = ['pickup_by', 'date', 'pickup_id', 'status', 'line_count', 'order_count'];

const environmentsSearchParam = [
	'environment_id',
	'environment_name',
	'status',
	'type',
	'hosting_location',
];

const CustomSearch = () => {
	const refSearchInput = useRef<HTMLInputElement>(null);

	const customers = useSelector((state: RootState) => state.customers);
	const licenses = useSelector((state: RootState) => state.licenses);
	const environments = useSelector((state: RootState) => state.environments);
	const users = useSelector((state: RootState) => state.users);
	const products = useSelector((state: RootState) => state.products);

	const [results, setResults] = useState<any>({
		customers: [],
		environments: [],
		licenses: [],
		products: [],
		users: [],
		logs: [],
	});

	const navigate = useNavigate();
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setSearchModalStatus(true);
		},
	});

	const searchItem = useCallback(
		(title: string, dataArray: any, columns: any[]) => {
			const searchResult: { [key: string]: any } = {};

			const filteredData = dataArray
				? dataArray.filter((data: any) => {
						let result = false;

						for (let i = 0; i < columns.length; i++) {
							if (
								String(data[columns[i]])
									.toLowerCase()
									.includes(formik.values.searchInput.toLowerCase())
							) {
								result = true;
							}
						}
						return result;
				  })
				: [];
			if (filteredData.length > 0) searchResult[title] = filteredData;

			return searchResult;
		},
		[formik.values.searchInput],
	);

	useEffect(() => {
		if (formik.values.searchInput) {
			setSearchModalStatus(true);
			refSearchInput?.current?.focus();
			setResults({
				...searchItem('customers', customers.data, customersSearchParam),
				...searchItem('licenses', licenses.data, licensesSearchParam),
				...searchItem('environments', environments.data, environmentsSearchParam),
				...searchItem('users', users.data, usersSearchParam),
				...searchItem('products', products.data, productsSearchParam),
			});
		}
		return () => {
			setSearchModalStatus(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.searchInput, searchItem, customers.data]);

	// const searchPages: {
	// 	[key: string]: {
	// 		id: string;
	// 		text: string;
	// 		path: string;
	// 		icon: string;
	// 	};
	// } = {
	// 	...dashboardPagesAdminMenuML,
	// };
	// const filterResult = Object.keys(searchPages)
	// 	.filter(
	// 		(key) =>
	// 			searchPages[key].text
	// 				.toString()
	// 				.toLowerCase()
	// 				.includes(formik.values.searchInput.toLowerCase()) ||
	// 			searchPages[key].path
	// 				.toString()
	// 				.toLowerCase()
	// 				.includes(formik.values.searchInput.toLowerCase()),
	// 	)
	// 	.map((i) => searchPages[i]);
	const resultLength =
		// filterResult.length +
		(parseInt(results?.licenses?.length) || 0) +
		(parseInt(results?.customers?.length) || 0) +
		(parseInt(results?.products?.length) || 0) +
		(parseInt(results?.logs?.length) || 0) +
		(parseInt(results?.environments?.length) || 0) +
		(parseInt(results?.users?.length) || 0);
	return (
		<>
			<div className='d-flex' data-tour='search'>
				<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Search...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
				/>
			</div>
			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus}>
					<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						ref={refSearchInput}
						name='searchInput'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search...'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</ModalHeader>
				<ModalBody>
					<caption>
						Results: <strong>{resultLength}</strong>
					</caption>

					{results?.licenses?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									<th scope='col'>Licenses</th>
								</tr>
							</thead>
							<tbody>
								{results?.licenses.map((item: any) => (
									<tr
										key={item.delivery_id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/licenses/${item.license_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.license_id}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.customers?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									<th scope='col'>Customers</th>
								</tr>
							</thead>
							<tbody>
								{results?.customers.map((item: any) => (
									<tr
										key={item.order_no}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/customers/${item.customer_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.customer_name}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.products?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									<th scope='col'>Products</th>
								</tr>
							</thead>
							<tbody>
								{results?.products.map((item: any) => (
									<tr
										key={item.pickup_id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/products/${item.product_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.product_name}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.logs?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									{/* <th scope='col'>
										<Box size={20} className='me-3' color='#f7a31c' />
									</th> */}
									<th scope='col'>
										{/* <Box size={20} className='me-3' color='#f7a31c' /> */}
										Logs
									</th>
								</tr>
							</thead>
							<tbody>
								{results?.logs.map((item: any) => (
									<tr
										key={item.pickup_id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/logs/${item.log_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.environment_name}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.environments?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									{/* <th scope='col'>
										<Box size={20} className='me-3' color='#f7a31c' />
									</th> */}
									<th scope='col'>
										{/* <Box size={20} className='me-3' color='#f7a31c' /> */}
										Environments
									</th>
								</tr>
							</thead>
							<tbody>
								{results?.environments.map((item: any) => (
									<tr
										key={item.environment_id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/environments/${item.environment_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.environment_name}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.users?.length && (
						<table className='table table-hover table-modern caption-top mb-2'>
							<thead>
								<tr>
									<th scope='col'>Users</th>
								</tr>
							</thead>
							<tbody>
								{results?.users.map((item: any, index: number) => (
									<tr
										key={index}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/users/${item.username}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>
											<span className='me-4'></span>
											{item.username}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{/* <table className='table table-hover table-modern caption-top mb-0'>
						<caption>Results: {resultLength}</caption>

						<thead className='position-sticky' style={{ top: -13 }}>
							<tr>
								<th scope='col'>Pages</th>
							</tr>
						</thead>
						<tbody>
							{filterResult.length ? (
								filterResult.map((item) => (
									<tr
										key={item.id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`../${item.path}`);
										}}>
										<td>
											{item.icon && (
												<Icon
													icon={item.icon}
													size='lg'
													className='me-2'
													color='primary'
												/>
											)}
											{item.text}
										</td>
									</tr>
								))
							) : (
								<tr className='table-active'>
									<td>No result found for query "{formik.values.searchInput}"</td>
								</tr>
							)}
						</tbody>
					</table> */}
					{(customers?.loading ||
						environments?.loading ||
						products?.loading ||
						users?.loading) && (
						<Row className='justify-content-center mb-4'>
							<Spinner isGrow size={20} className='me-2' />
							<Spinner isSmall size={20} isGrow className='me-2' />
							<Spinner isSmall size={20} isGrow />
						</Row>
					)}
				</ModalBody>
			</Modal>
		</>
	);
};

export default CustomSearch;

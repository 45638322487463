import { createSlice } from '@reduxjs/toolkit';
import { apiPost, functions } from '../helpers/axios';

type TState = {
	loading: boolean;
	data: any[];
	error: any;
};
const initialState: {
	loading: boolean;
	data: any[];
	error: any;
} = {
	loading: true,
	data: [],
	error: null,
};
const environmentsSlice = createSlice({
	name: 'environments',
	initialState,
	reducers: {
		set(state, action: { payload: TState }) {
			const { payload } = action;
			state.data = payload.data;
			state.error = payload.error;
			state.loading = payload.loading;
		},
		delete(state, action: { payload: { environment_id: string } }) {
			state.data = state.data.filter(
				(_data) => _data.environment_id !== action.payload.environment_id,
			);
		},
		updateEnvironment(state, action: { payload: { environment_id: string; data: any } }) {
			let index = state.data.findIndex(
				(_data) => _data.environment_id === action.payload.environment_id,
			);

			if (index === -1) {
				return;
			}

			state.data[index] = {
				...state.data[index],
				...action.payload.data,
			};
		},
	},
});
export const environmentsActions = environmentsSlice.actions;
export default environmentsSlice;

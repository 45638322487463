import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authPagesML, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import CustomFooter from '../pages/_layout/_footers/CustomFooter';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: authPagesML.custlogin.path, element: <CustomFooter /> },
	{ path: authPagesML.error404.path, element: <CustomFooter /> },
	{ path: authPagesML.adminlogin.path, element: <CustomFooter /> },
	{ path: demoPagesMenu.signUp.path, element: null },
	// { path: demoPagesMenu.page404.path, element: null },
	{ path: demoPagesMenu.knowledge.subMenu.grid.path, element: null },
	{ path: '*', element: null },
];

export default footers;

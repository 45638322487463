import * as React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { authPagesML } from '../../menu';
const AdminAuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isAuthenticated, user } = useAuth();
	// const role = "admin";
	if (!isAuthenticated) {
		window.localStorage.setItem('lastPath', window.location.pathname);
		return <Navigate to={authPagesML.adminlogin.path} />;
	}

	if (user?.provider === 'microsoft.com') {
		return <>{children}</>;
	} else {
		return <Navigate to={authPagesML.customerRedirect.path} />;
	}
};

export default AdminAuthGuard;

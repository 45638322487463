import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import HumansWebp from '../../../assets/img/scene8.webp';
import Humans from '../../../assets/img/scene8.png';
import { authPagesML, pageLayoutTypesPagesMenu } from '../../../menu';
import Button from '../../../components/bootstrap/Button';

const customerRedirect = () => {
	return (
		<PageWrapper title={authPagesML.customerRedirect.text}>
			<Page>
				<div className='row d-flex align-items-center h-100'>
					<div
						className='col-12 d-flex justify-content-center'
						style={{ fontSize: 'calc(3rem + 3vw)' }}>
						<p>Unauthorized</p>
					</div>
					<div className='text-dark fw-bold' style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
						Only Admin can access this page.
					</div>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button
							className='px-5 py-3'
							color='primary'
							isLight
							icon='HolidayVillage'
							tag='a'
							href='/'>
							Return to Customer Dashboard
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default customerRedirect;

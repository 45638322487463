import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	authPagesML,
	dashboardPagesAdminMenuML,
	dashboardPagesCustMenuML,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: authPagesML.custlogin.path, element: null },
	{ path: authPagesML.adminlogin.path, element: null },
	{ path: authPagesML.error404.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	// { path: dashboardPagesCustMenuML.dashboard.path, element: <DefaultAside /> },
	// { path: dashboardPagesCustMenuML.environments.path, element: <DefaultAside /> },
	// { path: dashboardPagesCustMenuML.licenses.path, element: <DefaultAside /> },
	// { path: dashboardPagesCustMenuML.invoices.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.dashboard.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.customers.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.customers.path + '/:customer_id', element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.environments.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.licenses.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.logs.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.products.path, element: <DefaultAside /> },
	{ path: dashboardPagesAdminMenuML.users.path, element: <DefaultAside /> },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: null },
];

export default asides;

import { createSlice } from '@reduxjs/toolkit';
// import { apiPost, functions } from '../helpers/axios';

type TState = {
	loading: boolean;
	data: any[];
	error: any;
};
const initialState: {
	loading: boolean;
	data: any[];
	error: any;
} = {
	loading: true,
	data: [],
	error: null,
};
const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		set(state: any, action: { payload: TState }) {
			const { payload } = action;
			state.data = payload.data;
			state.error = payload.error;
			state.loading = payload.loading;
		},
		setUser(state, action: { payload: { index: number; data: any } }) {
			const { index, data } = action.payload;
			state.data[index] = { ...state.data[index], ...data };
		},
		deleteUser(state, action: { payload: { email: string } }) {
			const { email } = action.payload;
			state.data = state.data.filter((_data) => _data.email !== email);
		},
	},
});
export const usersActions = usersSlice.actions;
export default usersSlice;

import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import {
	dashboardPagesAdminMenuML,
	dashboardPagesCustMenuML,
	dashboardPagesMenu,
} from '../../menu';
import Spinner from '../bootstrap/Spinner';
import showNotification from '../extras/showNotification';
import roles from './roles';
interface GuestGuardProps {
	children?: React.ReactNode;
}
const lastPath = window.localStorage.getItem('lastPath');

// For routes that can only be accessed by unauthenticated users
const GuestGuard: React.FC<GuestGuardProps> = ({ children }) => {
	const { isAuthenticated, isInitialized, user } = useAuth();
	if (!isInitialized) {
		return (
			<Container>
				<Col className='h-100 d-flex flex-column justify-content-center align-items-center'>
					<Row className='justify-content-center align-items-center'>
						<Spinner isGrow size={20} className='me-3' color={'dark'} />
						<Spinner isGrow size={20} className='me-3' color={'dark'} />
						<Spinner isGrow size={20} className='' color={'dark'} />
					</Row>
				</Col>
			</Container>
		);
	}

	if (isInitialized && isAuthenticated) {
		showNotification(
			'',
			<p>
				Logged in as <strong>{user.username?.length ? user.username : user.email}</strong>
			</p>,
			'success',
		);

		if (user.role === roles.customer) {
			return <Navigate to={dashboardPagesCustMenuML.dashboard.path} />;
		}
		if (user.provider === 'microsoft.com') {
			return (
				<Navigate to={lastPath?.length ? lastPath : dashboardPagesMenu.dashboard.path} />
			);
		}
		return <Navigate to={dashboardPagesCustMenuML.dashboard.path} />;
	}
	return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;

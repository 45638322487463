import { createSlice } from '@reduxjs/toolkit';
import { apiPost, functions } from '../helpers/axios';

type TState = {
	loading: boolean;
	data: { [key: string]: any };
	error: any;
};
const initialState: TState = {
	loading: true,
	data: {},
	error: null,
};

const customerSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		set(state, action: { payload: TState }) {
			const { payload } = action;
			state.data = payload.data;
			state.error = payload.error;
			state.loading = payload.loading;
		},
		update(state, action: { payload: { [key: string]: any } }) {
			const { payload } = action;
			state.data = { ...state.data, ...payload };
		},

		// delete(state, action: { payload: { customer_id: string } }) {
		// 	state.data = state.data.filter(
		// 		(_data) => _data.customer_id !== action.payload.customer_id,
		// 	);
		// },
	},
});
export const customerActions = customerSlice.actions;
export default customerSlice;

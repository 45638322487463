import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import customerSlice from './customer-slice';
import customersSlice from './customers-slice';
import environmentsSlice from './environments-slice';
import licensesSlice from './licenses-slice';
import logsSlice from './logs-slice';
import productsSlice from './products-slice';
import usersSlice from './users-slice';
const store = configureStore({
	reducer: {
		customers: customersSlice.reducer,
		environments: environmentsSlice.reducer,
		licenses: licensesSlice.reducer,
		products: productsSlice.reducer,
		users: usersSlice.reducer,
		environmentLogs: logsSlice.reducer,
		customer: customerSlice.reducer,
	},
});

export default store;
export type RootState = ReturnType<typeof store.getState>;

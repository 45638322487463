import { createSlice } from '@reduxjs/toolkit';
import { apiPost, functions } from '../helpers/axios';

type TState = {
	loading: boolean;
	data: any[];
	error: any;
};
const initialState: {
	loading: boolean;
	data: any[];
	error: any;
} = {
	loading: true,
	data: [],
	error: null,
};

const licensesSlice = createSlice({
	name: 'licenses',
	initialState,
	reducers: {
		set(state, action: { payload: TState }) {
			const { payload } = action;
			state.data = payload.data;
			state.error = payload.error;
			state.loading = payload.loading;
		},
		setLicense(state, action: { payload: { index: number; data: any } }) {
			const { index, data } = action.payload;
			state.data[index] = data;
		},
		add(state, action: { payload: { license_id: string; [key: string]: any } }) {
			const { payload } = action;
			state.data.unshift(payload);
		},
		delete(state, action: { payload: string }) {
			const { payload } = action;
			const licenses = state.data.filter((license) => license.license_id !== payload);
			state.data = licenses;
		},
	},
});
export const licensesActions = licensesSlice.actions;
export default licensesSlice;

import { createSlice } from '@reduxjs/toolkit';
import { apiPost, functions } from '../helpers/axios';

type LogStatePayload = {
	environment_id: string;
	logs: any[];
};
const initialState: {
	[key: string]: { [key: string]: any }[];
} = {};

const logsSlice = createSlice({
	name: 'logs',
	initialState,
	reducers: {
		setEnvironmentLogs(state, action: { payload: LogStatePayload }) {
			const { payload } = action;
			state[payload.environment_id] = payload.logs;
		},
		// setCustomer(state, action: { payload: { index: number; data: any } }) {
		// 	const { index, data } = action.payload;
		// 	state.data[index] = data;
		// },
	},
});
export const logsActions = logsSlice.actions;
export default logsSlice;

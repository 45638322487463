import React, { FC } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			// viewBox='0 0 1231.512 114.636'
			fill='none'
			viewBox='0 0 330.98 123.13'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='Layer_6_copy' data-name='Layer 6 copy'>
				<rect className='cls-7' y='0' width='110.1' height='110.1' rx='35' ry='35' />
				<path
					className='cls-5'
					d='m106.78,41.44v-20.65c-.11-2.7-.7-8.83-4.66-14.21-3.45-4.69-7.47-6.12-8.15-6.25-.64-.12-1.33-.2-1.33-.2-.56-.07-1.03-.1-1.37-.11-.46-.02-.85-.02-1.13-.02,1.08,1.2,2.74,3.33,3.85,6.41,1.12,3.08,1.21,5.77,1.15,7.39v34.53c0,3.8,2.6,6.88,5.81,6.88-3.21,0-5.81,3.08-5.81,6.88v34.42c.05,1.65-.08,4.34-1.19,7.4-1.06,2.9-2.57,4.96-3.62,6.18.93.06,2.32.04,3.91-.39,3.93-1.08,6.17-3.93,7.87-6.09,2.68-3.41,3.66-6.7,3.98-7.92.63-2.45.72-4.58.68-6.06v-20.65c0-7.6,5.21-13.77,11.62-13.77-6.43,0-11.62-6.15-11.62-13.77Z'
				/>
				<path
					className='cls-6'
					d='m110.7,41.44v-20.65c.1-1.22.59-8.28-4.66-14.21-4.63-5.23-10.61-6.08-12.07-6.24,1.07,1.07,2.79,3.07,3.95,6.07,1.21,3.12,1.24,5.86,1.15,7.39v34.53c0,3.8,2.6,6.88,5.81,6.88-3.21,0-5.81,3.08-5.81,6.88v34.42c.08,1.57,0,4.3-1.19,7.4-1.07,2.77-2.6,4.69-3.63,5.79,1.8-.23,7.6-1.22,11.8-6.09,2.69-3.13,3.64-6.6,3.98-7.92.63-2.45.72-4.58.68-6.06v-20.65c0-7.6,5.21-13.77,11.62-13.77-6.43,0-11.62-6.15-11.62-13.77Z'
				/>
			</g>
			<g id='Layer_3' data-name='Layer 3'>
				<g>
					<path
						className='cls-7'
						d='m135.46,14.57c.42-.21.95-.38,1.6-.52.64-.13,1.18-.2,1.6-.2.78,0,1.51.13,2.18.4s1.36.57,2.05.9c.72-.42,1.54-.74,2.45-.97.91-.22,1.84-.34,2.76-.34.84,0,1.6.2,2.29.58.69.39,1.27.89,1.75,1.5.48.61.85,1.31,1.12,2.09.27.78.4,1.56.4,2.34v10.15h-4.55v-6.32c0-2.18-.19-3.79-.57-4.84-.38-1.04-1.19-1.57-2.43-1.57-.36,0-.65.05-.86.16-.21.1-.44.31-.68.6v11.96h-4.55v-6.32c0-2.15-.18-3.76-.54-4.82-.36-1.06-1.07-1.59-2.12-1.59-.3,0-.64.08-1.01.25-.38.16-.67.37-.88.6v11.87h-4.55V14.3h4.55v.27Z'
					/>
					<path
						className='cls-7'
						d='m167.32,14.3h4.27v16.21h-4.27v-.54c-.56.27-1.2.46-1.91.58-.71.12-1.32.18-1.82.18-1.22,0-2.33-.2-3.36-.6s-1.91-.97-2.67-1.7c-.76-.73-1.35-1.6-1.78-2.61-.43-1.01-.64-2.11-.64-3.31s.25-2.22.76-3.26c.5-1.04,1.16-1.97,1.98-2.77.81-.8,1.75-1.44,2.8-1.92,1.05-.48,2.14-.71,3.27-.71.83,0,1.5.09,2,.27.5.18.96.31,1.38.4v-.22Zm-8.21,8.16c0,1.43.47,2.58,1.41,3.45.94.87,2.13,1.3,3.56,1.3,1.04,0,2.02-.22,2.91-.67l.04-.04v-7.53c-.42-.39-.95-.68-1.59-.87-.64-.19-1.19-.29-1.64-.29-1.43,0-2.58.42-3.43,1.25-.85.84-1.28,1.97-1.28,3.4Z'
					/>
					<path
						className='cls-7'
						d='m177.84,14.3v.88c.49-.34,1.05-.62,1.69-.84.64-.22,1.25-.33,1.85-.33h1.3v4.27h-1.95c-.73,0-1.4.24-1.99.73-.6.49-.89,1.11-.89,1.86v9.64h-4.55V14.3h4.55Z'
					/>
					<path
						className='cls-7'
						d='m188.65,4.06v17.61l6.33-7.37,4.54-.04-6.56,8.08,7.41,8.17h-5.34l-6.38-7.09v7.09h-4.55V4.06h4.55Z'
					/>
					<path className='cls-7' d='m206.57,4.06v26.45h-4.55V4.06h4.55Z' />
					<path className='cls-7' d='m208.28,14.3h4.27v16.21h-4.27V14.3Z' />
					<path
						className='cls-7'
						d='m219.94,7.41v6.89h3.13v3.41h-3.13v5.33c0,1.4.14,2.39.43,2.96.29.57.88.85,1.79.85.3,0,.6-.05.9-.16.3-.1.59-.23.86-.38v4.17c-.33.18-.73.31-1.21.4-.48.09-.93.13-1.35.13-1.2,0-2.18-.16-2.94-.49-.76-.33-1.37-.75-1.82-1.26-.45-.51-.76-1.06-.94-1.66-.18-.6-.27-1.17-.27-1.71v-8.19h-1.7c.27-.57.54-1.08.83-1.52.28-.45.57-.96.87-1.52v-5.33l4.55-1.93Z'
					/>
					<path
						className='cls-7'
						d='m232.67,13.89c1.02,0,2.01.16,2.97.47.96.31,1.81.79,2.56,1.43.74.64,1.34,1.46,1.79,2.44s.68,2.14.68,3.45c0,.36-.01.72-.04,1.1-.03.37-.08.77-.16,1.19h-11.46c.05.63.25,1.17.58,1.64.33.46.73.84,1.22,1.12.48.28.99.49,1.52.63.54.13,1.04.2,1.5.2,1.04,0,1.93-.18,2.66-.54.73-.36,1.45-.76,2.16-1.21l1.44,3.09c-.77.69-1.74,1.2-2.91,1.55-1.17.34-2.3.52-3.4.52-2.83,0-5.04-.75-6.64-2.26-1.59-1.51-2.39-3.65-2.39-6.43,0-1.16.21-2.26.64-3.29.43-1.03,1-1.92,1.73-2.67.73-.75,1.57-1.34,2.53-1.77.96-.43,1.96-.65,3.01-.65Zm3.59,6.91c0-.9-.31-1.63-.93-2.19-.62-.57-1.33-.85-2.12-.85-1.04,0-1.89.21-2.53.63-.65.42-1.2,1.24-1.67,2.46l7.25-.04Z'
					/>
				</g>
				<circle className='cls-6' cx='210.41' cy='9.6' r='2.13' />
				<path className='cls-7' d='m242.46,28.91v1.83h-.43v-1.83h-.54v-.4h1.52v.4h-.54Z' />
				<path
					className='cls-7'
					d='m243.04,30.73v-2.23h.4l.66,1.3c.11-.22.22-.43.33-.65s.22-.44.33-.65h.4v2.23h-.42s0-1.25,0-1.25c-.07.15-.15.29-.22.43-.07.14-.15.29-.22.43-.01.02-.03.05-.04.09-.02.03-.04.07-.05.11s-.04.07-.05.1c-.02.03-.03.06-.04.09l-.19-.39c-.04-.09-.08-.16-.12-.23-.04-.07-.07-.13-.1-.2-.03-.06-.07-.13-.1-.2-.04-.07-.07-.15-.12-.23v1.25s-.43,0-.43,0Z'
				/>
			</g>
			<g id='Layer_4' data-name='Layer 4'>
				<g>
					<path
						className='cls-6'
						d='m140.95,59.63v14.09c0,3.89,1.31,7.21,3.92,9.96,2.61,2.75,5.77,4.13,9.46,4.13v10.56c-6.48,0-12.01-2.4-16.57-7.21-4.57-4.81-6.85-10.62-6.85-17.44v-38.74h10.04v14.09h13.38v10.56h-13.38Z'
					/>
					<path
						className='cls-6'
						d='m203.84,73.71c0,6.82-2.28,12.64-6.85,17.44-4.57,4.81-10.09,7.21-16.57,7.21s-12.01-2.4-16.57-7.21c-4.57-4.81-6.85-10.62-6.85-17.44s2.28-12.64,6.85-17.44c4.56-4.81,10.09-7.21,16.57-7.21,5.05,0,9.51,1.45,13.38,4.35v-4.35h10.04v24.65Zm-13.96,9.96c2.61-2.75,3.92-6.07,3.92-9.96s-1.31-7.21-3.92-9.96c-2.61-2.75-5.77-4.13-9.46-4.13s-6.85,1.38-9.46,4.13c-2.61,2.75-3.92,6.07-3.92,9.96s1.31,7.21,3.92,9.96c2.61,2.75,5.77,4.13,9.46,4.13s6.85-1.38,9.46-4.13Z'
					/>
					<path
						className='cls-6'
						d='m206.52,73.71c0-6.82,2.28-12.64,6.85-17.44,4.56-4.81,10.09-7.21,16.57-7.21v10.56c-3.69,0-6.85,1.38-9.46,4.13-2.61,2.75-3.92,6.07-3.92,9.96v24.65h-10.04v-24.65Z'
					/>
					<path
						className='cls-6'
						d='m279.45,73.71c0,6.82-2.28,12.64-6.85,17.44-4.57,4.81-10.09,7.21-16.57,7.21s-12.01-2.4-16.57-7.21c-4.57-4.81-6.85-10.62-6.85-17.44s2.28-12.64,6.85-17.44c4.56-4.81,10.09-7.21,16.57-7.21,5.05,0,9.51,1.45,13.38,4.35v-18.43h10.04v38.74Zm-13.96,9.96c2.61-2.75,3.92-6.07,3.92-9.96s-1.31-7.21-3.92-9.96c-2.61-2.75-5.77-4.13-9.46-4.13s-6.85,1.38-9.46,4.13c-2.61,2.75-3.92,6.07-3.92,9.96s1.31,7.21,3.92,9.96c2.61,2.75,5.77,4.13,9.46,4.13s6.85-1.38,9.46-4.13Z'
					/>
					<path
						className='cls-6'
						d='m287.15,45.54c-1.39,0-2.58-.51-3.55-1.54-.98-1.03-1.46-2.27-1.46-3.74s.49-2.71,1.46-3.74c.98-1.03,2.16-1.54,3.55-1.54s2.58.51,3.55,1.54c.98,1.03,1.46,2.27,1.46,3.74s-.49,2.72-1.46,3.74c-.98,1.03-2.16,1.54-3.55,1.54Zm5.02,3.52v49.3h-10.04v-49.3h10.04Z'
					/>
					<path
						className='cls-6'
						d='m308.23,49.06h20.07v10.56h-18.56c-3.55.04-5.28.77-5.17,2.2,0,2.13,3.71,4.16,11.13,6.11,5.44,1.43,9.23,3.21,11.37,5.34,2.14,2.13,3.21,5.19,3.21,9.19,0,5.25-1.45,9.21-4.34,11.89-2.89,2.68-7.13,4.02-12.7,4.02h-18.4l-.05-10.56h18.19c4.39-.18,6.67-1.39,6.85-3.63,0-1.91-1.43-3.25-4.29-4.02-9.65-2.53-15.52-5.05-17.59-7.57-2.07-2.51-3.11-5.55-3.11-9.11,0-9.68,4.46-14.49,13.38-14.42Z'
					/>
				</g>
			</g>
			<g id='Layer_11' data-name='Layer 11'>
				<path
					className='cls-5'
					d='m61.86,55.7h-9.01v-13.51h9.01v13.51Zm-13.51,0h-9.01v-13.51h9.01v13.51Zm0,9.01h-9.01v-4.5h9.01v4.5Zm20.27,11.26v-40.54h-2.25v-4.5h-13.51v-2.26c0-3-4.5-3-4.5,0v2.25h-13.51v4.5h-2.25v40.54h-2.25v4.5h40.54v-4.5h-2.25Z'
				/>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;

import { createSlice } from '@reduxjs/toolkit';
import { apiPost, functions } from '../helpers/axios';

type TState = {
	loading: boolean;
	data: any[];
	error: any;
};
const initialState: {
	loading: boolean;
	data: any[];
	error: any;
} = {
	loading: true,
	data: [],
	error: null,
};

const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		set(state, action: { payload: TState }) {
			const { payload } = action;
			state.data = payload.data;
			state.error = payload.error;
			state.loading = payload.loading;
		},
		add(state, action: { payload: { customer_id: string; [key: string]: any } }) {
			const { payload } = action;
			state.data.unshift(payload);
		},
		setCustomer(state, action: { payload: { index: number; data: any } }) {
			const { index, data } = action.payload;
			state.data[index] = data;
		},
		setLicense(state, action: { payload: { index: number; licenseIndex: number; data: any } }) {
			const { index, data, licenseIndex } = action.payload;
			state.data[index].licenses[licenseIndex] = data;
		},
		delete(state, action: { payload: { customer_id: string } }) {
			state.data = state.data.filter(
				(_data) => _data.customer_id !== action.payload.customer_id,
			);
		},
		deleteLicense(state, action: { payload: { customer_id: string; license_id: string } }) {
			let index = state.data.findIndex(
				(_data) => _data.customer_id === action.payload.customer_id,
			);

			if (index === -1) {
				return;
			}

			state.data[index] = {
				...state.data[index],
				licenses: state.data[index].licenses.filter(
					(license: any) => license.license_id !== action.payload.license_id,
				),
			};
		},
	},
});
export const customersActions = customersSlice.actions;
export default customersSlice;

import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import MarkLiteLogo from '../../../components/MarkLiteLogo';

const CustomFooter = () => {
	const { darkModeStatus } = useDarkMode();
	const fotterLogin = 'footerLogin';
	return (
		<Footer footerType={fotterLogin}>
			<div className='container-fluid '>
				<div className='row  dflex   justify-content-center'>
					<div className='col-auto mt-2 m-0 p-0    '>
						<span className='fw-light '>Copyright © 2023 |</span>
					</div>
					<div className='col-auto ms-0  '>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold '>
								<MarkLiteLogo width={80} />
							</small>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default CustomFooter;

import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CustomSearch from '../../../components/CustomSearch';

const DashboardHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<CustomSearch />
			</HeaderLeft>
			{/* <CommonHeaderRight afterChildren={<CommonHeaderChat />} /> */}
		</Header>
	);
};

export default DashboardHeader;
